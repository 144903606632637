
import { dispatchFinishRegister, dispatchUserEdit } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';
import { Component, Vue } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';

@Component({
  directives: {mask},
  components: {
  },
})

export default class SignUpWholesaler extends Vue {
  public title: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public middleName: string = '';
  public phone: string = '';
  public formError = false;
  public loadingState = {
    submit: false,
    page: true,
  };

  public mounted() {
    const isLoggedIn = readIsLoggedIn(this.$store);
    if (!isLoggedIn) {
      this.$router.push('/sign-up');
    }
    this.loadingState.page = false;
  }

  public generatePayload() {
    const formData = new FormData();
    formData.append("title", this.title);
    formData.append("first_name", this.firstName);
    formData.append("last_name", this.lastName);
    formData.append("middle_name", this.middleName);
    formData.append("phone", this.phone);
    return formData;
  }

  public async handleSubmit() {
    if (!this.title || !this.firstName || !this.lastName || !this.phone) {
      this.formError = true;
      // @ts-ignore
      this.$toast.error(this.$t('Пожалуйста, заполните все поля'), {
        duration: 3000, position: 'bottom-right',
      });
      return false;
    }
    this.formError = false;
    this.loadingState.submit = true;
    const patload = this.generatePayload();
    const response = await dispatchUserEdit(this.$store, patload);
    dispatchFinishRegister(this.$store);
    this.$router.push('/home');
    this.loadingState.submit = false;
  }
}
